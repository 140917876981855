import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Img from 'gatsby-image'

import styles from '../components/contact.module.css'



class RootIndex extends React.Component {
  render() {
    const page = get(this, 'props.data.page')

    return (
      <Layout location={this.props.location} wideNav noTop noFooter fullHeight>
        <SEO title={page.title} description={page.subtitle}></SEO>
        <div className={styles.content + " content"}>
          <div className={styles.videoContainer}>
          <video className={styles.video} autoPlay loop muted playsInline>
            <source src="/contact.mp4" type="video/mp4"/>
          </video>
          </div>
          <div className={styles.body}>
            {page.body != null && <div dangerouslySetInnerHTML={{
                  __html: page.body.childContentfulRichText.html,
            }}></div>}
            <form className={styles.contactForm} method="post" action="/contact/thankyou" netlify-honeypot="bot-field" data-netlify="true" name="contact">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <input type="email" name="email" placeholder="Email" required/>
              <textarea type="text" name="message" placeholder="Message" required></textarea>
              <button type="submit" value="Submit">Let’s talk</button>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }

    page: contentfulPage(name: {eq: "Contact"}) {
      title
      subtitle
      body {
        childContentfulRichText {
          html
        }
      }
      icon {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
